import React, { Component } from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { WebMapView, GeoInfo, SkogSvaedi } from "../components/WebMapView"
import TreeBondingChart, {
  AllCo2XlsxAnnually,
} from "../components/WebMapView/treeBondingChart"
import IceTree from "../components/WebMapView/iceTree"
import IceTrees, {TreeAreaPlaces} from "../components/WebMapView/iceTrees"
import NoTrees from "../components/WebMapView/noTrees"
import TreeGeo from "../components/WebMapView/treeGeo"
import MyForest from "../components/WebMapView/myForest"
import LandTypeDropDown, { LandType } from "../components/WebMapView/landtype"
import { CAge, Place, Sop } from "../components/WebMapView/liveness"

export interface dataMat {
  allCo2XlsxAnnually: {
    edges: CAge[]
  }
  allTreeAreaCodesXlsxSg: {
    edges: Place[]
  }
  allTreeAreaCodesXlsxSf: {
    edges: Place[]
  }
  allTreeAreaCodesXlsxRl: {
    edges: Place[]
  }
  allTreeAreaCodesXlsxIb: {
    edges: Place[]
  }
  allTreeAreaCodesXlsxAo: {
    edges: Place[]
  }
}

export interface EstimateProps {
  data: dataMat
}

export interface EstimateState {
  geoinfo?: GeoInfo
  landtype?: LandType
}

export const pageQuery = graphql`
  {
    allCo2XlsxAnnually {
      edges {
        node {
          Po0
          Po2
          Po_2
          SS0
          SS2_4
          SS6_10
          Lp0
          Lp2
          Lp4
          RL4
          RL8
          RL12
          RL16
          IB5
          IB8
          age
        }
      }
    }
    allTreeAreaCodesXlsxSg {
      edges {
        node {
          code
          name
        }
      }
    }
    allTreeAreaCodesXlsxSf {
      edges {
        node {
          code
          name
        }
      }
    }
    allTreeAreaCodesXlsxRl {
      edges {
        node {
          code
          name
        }
      }
    }
    allTreeAreaCodesXlsxIb {
      edges {
        node {
          code
          name
        }
      }
    }
    allTreeAreaCodesXlsxAo {
      edges {
        node {
          code
          name
        }
      }
    }
  }
`

class EstimatePage extends Component<EstimateProps, EstimateState> {
  constructor(props: EstimateProps) {
    super(props)
    this.state = {
      geoinfo: undefined,
    }
    this.handleMapClicked = this.handleMapClicked.bind(this)
  }

  handleMapClicked(geoinfo: GeoInfo) {
    if (typeof geoinfo !== "undefined") {
      this.setState({ geoinfo: geoinfo })
    } else {
      this.setState({ geoinfo: undefined })
    }
  }

  getPlaceNameFromList(places: Place[], code: string): string {
    return places
      .filter(function({ node }) {
        return node.code == code
      })
      .map(({ node }) => {
        return node.name
      })[0]
  }

  getTreeAreaNames(skogsvaedi: SkogSvaedi): TreeAreaPlaces {
    return {
      AO : this.getPlaceNameFromList(
      this.props.data.allTreeAreaCodesXlsxAo.edges,
      skogsvaedi.AO
    ),
    SG : this.getPlaceNameFromList(
      this.props.data.allTreeAreaCodesXlsxSg.edges,
      skogsvaedi.SG
    ),
    SF : this.getPlaceNameFromList(
      this.props.data.allTreeAreaCodesXlsxSf.edges,
      skogsvaedi.SF
    ),
    RL : this.getPlaceNameFromList(
      this.props.data.allTreeAreaCodesXlsxRl.edges,
      skogsvaedi.RL
    ),
    IB : this.getPlaceNameFromList(
      this.props.data.allTreeAreaCodesXlsxIb.edges,
      skogsvaedi.IB
    )
    }
  }

  render() {
    return (
      <Layout>
        <SEO title="Kolefnisreiknivél" />
        <WebMapView
          zoom={6}
          long={-18.686}
          lat={64.87}
          livenessLayerURL="https://services1.arcgis.com/1RRKG8ufDU6bePrh/arcgis/rest/services/Skogarkolefnisreiknir_26112020/FeatureServer/0"
          onMapClicked={this.handleMapClicked}
        />
        {typeof this.props.data === "undefined" ||
        typeof this.state.geoinfo === "undefined" ||
        typeof this.state.geoinfo.groska === "undefined" ||
        Object.values(this.state.geoinfo.groska).every(
          el => el === undefined
        ) ? (
          <NoTrees geoinfo={typeof this.state.geoinfo === "undefined" ? null : this.state.geoinfo} 
          treeAreaPlaces={typeof this.state.geoinfo === "undefined" ? null : this.getTreeAreaNames(this.state.geoinfo.skogsvaedi)}/>
        ) : (
          <React.Fragment>
            <TreeGeo geoinfo={this.state.geoinfo}/>
            <p>
              Mælingar og rannsóknir sýna að eftirfarandi trjátegundir eru
              lífvænlegar og að kolefnisbinding þeirra gæti orðið eftirfarandi.
            </p>
            <TreeBondingChart
              geoinfo={this.state.geoinfo}
              data={this.props.data}
            />
            <p>
              Við þetta bætist losun eða binding í jarðvegi í mismunandi landi{" "}
              {LandType.DrainedWetland.toLocaleString("de-DE")}/
              {LandType.WellHeeledDryland.toLocaleString("de-DE")}/
              {LandType.PartlyHeeledDryland.toLocaleString("de-DE")} tonn CO
              <sub>2</sub> ígildi á hektara og ári og binding í sópi{" "}
              {Sop.toLocaleString("de-DE")} tonn CO
              <sub>2</sub> á hektara og ári. Velja þarf hvort borið er á
              plönturnar við gróðursetningu og aftur eftir fimm ár. Ef það er
              gert má búast við að heildarlosun gróðurhúsloftegunda vegna
              áburðargjafar verði 0,09 tonn CO<sub>2</sub> ígildi á hektara.
            </p>
            <p>
              <b>Ítarlegri upplýsingar um hverja trjátegund</b>
            </p>
            <p>
              Hér fyrir neðan eru taldar upp þær trjátegundir sem teljast ræktanlegar
              á staðnum sem er valinn og sýndir líklegustu gróskuflokkar þeirra á þessum
              stað. Í sumum tilvikum fara trjátegundirnar, ein, fleiri eða allar í
              svokallaðan biðflokk. Það þýðir annaðhvort að mælingar sem gerðar hafa
              verið í nágrenni staðarins benda til að skógrækt með þessum trjátegundum
              sé ekki raunhæfur kostur eða að mælingar á vexti eru af of skornum skammti
              að hægt sé að áætla kolefnisbindingu þeirra. Ef skjól skiptir máli er
              birtur gróskuflokkur fyrir skjólgóð svæði og síðan gróskuflokkur án skjóls
              í sömu línu. Í sumum tilvikum enda skjóllítill svæði í biðflokk.  
            </p>
            <IceTrees geoinfo={this.state.geoinfo} 
            treeAreaPlaces={this.getTreeAreaNames(this.state.geoinfo.skogsvaedi)} />
            <MyForest data={this.props.data} geoinfo={this.state.geoinfo} />
            <br />
          </React.Fragment>
        )}
      </Layout>
    )
  }
}

export default EstimatePage
